/** @jsx jsx */
import { jsx, Button, Box } from "theme-ui";
import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { motion } from "framer-motion";
import { colors } from "../style_dependencies/variables";
import { useEndpoint } from "../utils/useEndpoint";

const bounceTransition = {
  y: {
    ease: "easeIn",
  },
};

export default function ButtonLink({ data }) {
  const { isLoading, getLink } = useEndpoint();
  const { btnName, btnURL, btnType, banner, demoBtn } = data;

  function open(url) {
    const win = window.open(url, "_blank");
    if (win !== null) {
      win.focus();
    }
  }

  const getDemoLink = async () => {
    const obtained = await getLink();
    if (obtained) {
      open(obtained);
    }
  };

  return demoBtn ? (
    <Box sx={banner ? styles.bannerBtn : styles.btn}>
      <motion.div
        transition={bounceTransition}
        whileHover={{ y: ["0%", "-15%"] }}
      >
        <Button
          variant="primary"
          aria-label={btnName}
          sx={btnType === "coloredBtn" ? styles.coloredBtn : styles.simpleBtn}
          onClick={(e) => {
            e.preventDefault();
            getDemoLink();
          }}
        >
          {isLoading ? (
            "Please wait..."
          ) : (
            <>
              {btnName}
              <ChevronRightIcon
                sx={
                  btnType === "coloredBtn"
                    ? styles.coloredBtn.chevron
                    : styles.simpleBtn.chevron
                }
              />
            </>
          )}
        </Button>
      </motion.div>
    </Box>
  ) : (
    <a
      href={btnURL}
      variant="default"
      sx={banner ? styles.bannerBtn : styles.btn}
      target="_blank"
      rel="noreferrer"
    >
      <motion.div
        transition={bounceTransition}
        whileHover={{ y: ["0%", "-15%"] }}
      >
        <Button
          variant="primary"
          aria-label={btnName}
          sx={btnType === "coloredBtn" ? styles.coloredBtn : styles.simpleBtn}
        >
          {btnName}
          <ChevronRightIcon
            sx={
              btnType === "coloredBtn"
                ? styles.coloredBtn.chevron
                : styles.simpleBtn.chevron
            }
          />
        </Button>
      </motion.div>
    </a>
  );
}

const styles = {
  btn: {
    textDecoration: "none",
    "@media screen and (max-width: 356px)": {
      m: "0 auto",
      width: "75%",
    },
  },

  coloredBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    backgroundColor: colors.btnColor,
    borderRadius: "2rem",
    padding: "8px 12px 6px 16px !important",
    color: "white",
    marginRight: "10px",
    fontSize: "1rem !important",
    fontWeight: "600",

    chevron: {
      marginLeft: "3px",
      marginTop: "-3px",
      fontSize: "1.2rem !important",
      fontWeight: "600",
    },

    "@media screen and (max-width: 356px)": {
      m: "0 auto",
    },
  },
  simpleBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    backgroundColor: "white",
    borderRadius: "2rem",
    padding: "8px 12px 6px 16px !important",
    color: colors.btnColor,
    fontSize: "1rem !important",
    fontWeight: "600",

    chevron: {
      marginLeft: "3px",
      marginTop: "-2px",
      fontSize: "1.2rem !important",
      fontWeight: "600",
    },

    "@media screen and (max-width: 356px)": {
      mx: "auto",
      mt: "1.5rem !important",
      boxShadow: "rgba(100, 100, 111, 0.1) 0px 1px 5px 0px",
    },
  },

  bannerBtn: {
    textDecoration: "none",
    "@media screen and (max-width: 356px)": {
      width: "75%",

      button: {
        m: "unset",
      },
    },
  },
};
