import React, { useEffect, useState } from "react";
import axios from "axios";

export const useEndpoint = () => {
  const [demoLink, setDemoLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [controller, setController] = useState();

  const getLink = async () => {
    try {
      setIsLoading(true);
      const ctrl = new AbortController();
      setController(ctrl);

      const response = await axios.post("https://api.fapshi.com/demo-pay", {
        signal: ctrl.signal,
      });
      setDemoLink(response.data.link);

      return response.data.link;
    } catch (err) {
      if (err.isAxiosError) {
        if (err.response) {
          setError(err.response.data.message);
          setTimeout(() => {
            setError(false);
          }, 5000);
        } else {
          setError("We were unable to fetch the demo link! Please try again.");
        }
      } else {
        setError("We were unable to fetch the demo link! Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      controller && controller.abort();
    };
  }, [controller]);

  return {
    isLoading,
    demoLink,
    error,
    getLink,
  };
};
