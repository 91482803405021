/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ data, lang, meta }) {
  const { description, keywords, title, pathname } = data;
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            author
            url
            image
            imageAlt
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const siteKeywords = keywords || site.siteMetadata.keywords;
  const siteUrl = pathname
    ? `${site.siteMetadata.url}/${pathname}` || site.siteMetadata.url
    : `${site.siteMetadata.url}`;
  const image = `${site.siteMetadata.url}${site.siteMetadata.image}`;
  const imageAlt = site.siteMetadata.imageAlt;
  const canonical = pathname
    ? `${site.siteMetadata.url}/${pathname}`
    : `${site.siteMetadata.url}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      link={
        canonical
          ? [
              {
                rel: `canonical`,
                href: canonical,
              },
            ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: siteKeywords,
        },
        {
          property: `og:url`,
          content: siteUrl,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `og:image:alt`,
          content: imageAlt,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:image`,
          content: image,
        },
        {
          name: `twitter:image:alt`,
          content: imageAlt,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  imageAlt: PropTypes.string.isRequired,
  pathname: PropTypes.string,
};

export default SEO;
